import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { Deployment } from "../../../api/lib";
import { ProviderIconView } from "../../deployment/ProviderIcon";
import { useProvider } from "./useProvider";
import { useRegion } from "./useRegion";

export const DeploymentRegionCell = ({ deployment }: { deployment: Deployment }) => {
  const { data: region, isLoading: isLoadingRegion } = useRegion(deployment.region_id);
  const { data: provider, isLoading: isLoadingProvider } = useProvider(region?.provider_id);
  if (isLoadingRegion || isLoadingProvider) {
    return <Spinner size="xs" />;
  }

  return (
    <Flex gap="2" alignItems="center">
      <Box height="20px" width="20px" flexShrink="0">
        <ProviderIconView providerId={provider?.id} />
      </Box>
      <Text>{region?.location || "-"}</Text>
    </Flex>
  );
};
