//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { Box, Button, Card, CardBody, CardHeader, Code, Flex, Heading, Image, Link, Text, useClipboard, useToast } from "@chakra-ui/react";
import React from "react";
import networkXLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/networkx-logo.png";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { CopyRootPassword } from "../example/CopyRootPassword";

const CopyDeploymentEndpoint = () => {
  const { deployment } = useDeploymentStore();
  const { status = {} } = deployment;
  const { endpoint: deploymentEndpoint } = status;
  const { onCopy, hasCopied: hasCopiedDeploymentEndpoint } = useClipboard(deploymentEndpoint || "", 5000);
  const toast = useToast();

  const onCopyDeploymentEndpoint = async () => {
    onCopy();
    toast({
      title: `Application endpoint copied to clipboard`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Button
      variant="link"
      onClick={onCopyDeploymentEndpoint}
      fontWeight="normal"
      fontSize="normal"
      width="min-content"
      height="10px"
      color="gray.800"
      _hover={{
        color: "gray.950",
      }}
    >
      <Flex alignItems="center" gap="2">
        <Text>{deploymentEndpoint}</Text>
        {hasCopiedDeploymentEndpoint ? <CheckIcon color="green" /> : <CopyIcon />}
      </Flex>
    </Button>
  );
};

const NetworkXCard = () => {
  return (
    <Card color="gray.950" boxShadow="md" borderWidth="1px" borderColor="gray.100">
      <CardHeader display="flex" justifyContent="space-between" alignItems="center">
        <Heading size="md">Are you a NetworkX user?</Heading>
        <Image boxSize="14" src={networkXLogo} alt="NetworkX Logo" />
      </CardHeader>
      <CardBody display="flex" gap={6} flexDirection="column">
        <Text>Here are your connection details:</Text>
        <Box>
          <Text>
            <Text as="span" fontWeight="bold">
              Deployment endpoint:
            </Text>{" "}
            <CopyDeploymentEndpoint />
          </Text>
          <Text as="span" fontWeight="bold">
            Root password:{" "}
            <CopyRootPassword
              copyPasswordText={
                <Code
                  color="gray.800"
                  _hover={{
                    color: "gray.950",
                  }}
                  backgroundColor="white"
                >
                  ****************
                </Code>
              }
            />
          </Text>
        </Box>
        <Text>
          Check out{" "}
          <Link
            color="blue.600"
            _hover={{ color: "blue.700", textDecoration: "underline" }}
            href="https://github.com/arangodb/nx-arangodb/blob/main/README.md"
            target="_blank"
            fontWeight="bold"
            textDecorationLine="underline"
          >
            our tutorial
          </Link>{" "}
          to learn more about using NetworkX adapter.
        </Text>
      </CardBody>
    </Card>
  );
};

export { NetworkXCard };
