//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import { find, isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { Dropdown, Icon, Message, Segment } from "semantic-ui-react";
import { ContentActionButtonEdit, FormActionButtonDropdown } from "../../ui/_buttons";
import { Section, SectionHead, SectionHeader, SectionButtons, SectionContent } from "../../ui/_section";
import { FloatRight } from "../../ui/_style";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import { ConfigurationView } from "./ConfigurationView";
import { OverviewView, OverviewStatsView } from "./OverviewViews";
import {
  CPUSizeList as ApiCPUSizeList,
  Deployment as ApiDeployment,
  DeploymentMigration,
  DeploymentReplication as ApiDeploymentReplication,
  NodeSizeList as ApiNodeSizeList,
  Plan as ApiSupportPlan,
  PrepaidDeployment as ApiPrepaidDeployment,
} from "../../api/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import PrivateNetworkSummaryView from "../private-network/PrivateNetworkSummaryView";
import { PrivateEndpointService as ApiPrivateEndpointService } from "../../api/network/v1/network";
import { IPasswordDeploymentPageArgs } from "./PasswordSettingsModalView";
import { AWSPrincipal, RegionProviders } from "../private-network/types";
import MigrationStatusView from "./MigrationStatusView";
import { LearningContentSections } from "./LearningContentSections";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { useDeploymentPermissions } from "./useDeploymentPermissions";
import { RenderGuard } from "../../util/RenderGuard";
import { FreeTierStats } from "./FreeTierStats";

const StrongSegment = styled(Segment)`
  font-size: 110% !important;
`;

interface IOverviewTabContentArgs extends IWithRefreshProps, RouteComponentProps, IPasswordDeploymentPageArgs {
  prepaidDeployment: ApiPrepaidDeployment | undefined;
  nodeSizes: ApiNodeSizeList;
  cpuSizes: ApiCPUSizeList;
  deployment: ApiDeployment;
  processing: boolean;
  rootPasswordCopied: boolean;
  isDiskAlmostFull?: boolean;
  tierHasSupportPlans: boolean;
  loadingRootPassword: boolean;
  showRootPassword: boolean;
  supportPlans: ApiSupportPlan[];
  isPrivateNetworkAvailable: boolean;
  privateNetworkDetails?: ApiPrivateEndpointService;
  onCopyRootPassword: () => void;
  onResumeDeployment: () => void;
  onEditDeployment: () => void;
  onUpdatePrepaidDeployment: () => void;
  onShowRootPasswordToggle: () => void;
  handleConversionToPrivateNetwork: () => void;
  onSubscriptionIDChange: (subscriptionID: string[]) => Promise<{ error: unknown } | undefined>;
  onAlternateDNSNamesChange: (data: { alternateDNSNames: string[]; enablePrivateDNS?: boolean }) => Promise<{ error: unknown } | undefined>;
  onAWSPrincipalsChange: (awsPrincipals: AWSPrincipal[]) => Promise<{ error: unknown } | undefined>;
  onGCPProjectNameChange: (projectNames: string[]) => Promise<{ error: unknown } | undefined>;
  onClickSupportRequest: () => void;
  provider: RegionProviders;
  replicationDetails?: ApiDeploymentReplication;
  organization: ApiOrganization;
  migrationStatus?: DeploymentMigration;
}

const OverviewTabContent = (args: IOverviewTabContentArgs) => {
  const { isDeploymentEditAllowed, isPrivateEndpointFeatureAllowed } = useDeploymentPermissions();
  const isPrepaid = !!args.prepaidDeployment;
  const prepaidModel = (args.prepaidDeployment || {}).model || {};
  const prepaidNodeSize = find(args.nodeSizes.items || [], (x) => x.id === prepaidModel.node_size_id);
  const prepaidCPUSize = find(args.cpuSizes.items || [], (x) => x.id === (prepaidNodeSize || {}).cpu_size);
  const hasPrepaidDeploymentExpired = moment(args.deployment.prepaid_deployment_ends_at).isBefore(moment());
  const isDeploymentPaused = !!args.deployment.is_paused;
  const hasPrepaidUpdate = isPrepaid && !!args.deployment.is_prepaid_deployment_update_available;
  const hasReplaceBy = !!args.deployment.replace_version_by;
  const replaceBy = args.deployment.replace_version_by || {};
  const replaceByDate = replaceBy.auto_update_date;
  const hasCustomImage = !isEmpty(args.deployment.custom_image);
  const upgradeRecommendation = args.deployment.upgrade_recommendation || {};
  const hasUpgradeRecommendation = !!args.deployment.upgrade_recommendation;
  const currentNodeSize = find(args.nodeSizes.items || [], (x) => x.id === (args.deployment.model || {}).node_size_id);
  const currentCPUSize = find(args.cpuSizes.items || [], (x) => x.id === (currentNodeSize || {}).cpu_size);
  const showPrivateNetworkChangeButton = args.isPrivateNetworkAvailable && isPrivateEndpointFeatureAllowed && isEmpty(args.privateNetworkDetails);
  const { status: replicationStatus = {} } = args.replicationDetails || {};
  const { phase } = replicationStatus;
  const isMigrationCompleted = phase === "Completed";
  const isMigrationMoreThanAWeekAgo = moment(replicationStatus.phase_updated_at).isBefore(moment().subtract(1, "weeks"));
  const isMigrationCompletedMoreThanAWeekAgo = isMigrationCompleted && isMigrationMoreThanAWeekAgo;
  const is_free_tier = args.organization.tier && args.organization.tier.id == "free";
  return (
    <div>
      {is_free_tier && (
        <>
          <LearningContentSections />
          <FreeTierStats isPrepaid={isPrepaid} />
        </>
      )}
      <Section>
        <SectionHead>
          <SectionHeader title="Deployment details" />
          <SectionButtons>
            {isDeploymentEditAllowed && (
              <DeploymentPausedModal
                onResumeDeployment={args.onResumeDeployment}
                onClick={!showPrivateNetworkChangeButton ? args.onEditDeployment : undefined}
                trigger={
                  !showPrivateNetworkChangeButton ? (
                    <ContentActionButtonEdit primary disabled={args.processing || args.deployment.status?.read_only} title="Edit" />
                  ) : (
                    <Dropdown trigger={<FormActionButtonDropdown title="Edit" primary />} icon={null} pointing="top right">
                      <Dropdown.Menu>
                        <Dropdown.Item disabled={args.processing} onClick={args.onEditDeployment} icon={null}>
                          <Icon name="edit" /> Edit deployment
                        </Dropdown.Item>
                        <Dropdown.Item onClick={args.handleConversionToPrivateNetwork}>
                          <Icon name="shield" />
                          Change to private endpoint
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }
              />
            )}
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          <RenderGuard renderIf={!!args.migrationStatus?.status?.phase || !!args.migrationStatus?.created_at}>
            <RenderGuard
              renderIf={
                args.migrationStatus?.status?.phase?.toLowerCase() !== "error" &&
                args.migrationStatus?.status?.phase?.toLowerCase() !== "failed" &&
                args.migrationStatus?.status?.phase?.toLowerCase() !== "completed"
              }
            >
              <Message basic>This free deployment is being upgraded to a paid one and will be automatically deleted after the upgrade.</Message>
            </RenderGuard>
            <RenderGuard
              renderIf={args.migrationStatus?.status?.phase?.toLowerCase() === "error" || args.migrationStatus?.status?.phase?.toLowerCase() === "failed"}
            >
              <Message negative>
                <Message.Header>Upgrade failed</Message.Header>
                <p>There was an error while upgrading the deployment.</p>
              </Message>
            </RenderGuard>
          </RenderGuard>

          <OverviewView
            {...args}
            isPasswordRotationScheduleEnabled={args.deployment.is_scheduled_root_password_rotation_enabled}
            loadingRootPassword={args.loadingRootPassword}
            onCopyRootPassword={args.onCopyRootPassword}
            showRootPassword={args.showRootPassword}
            onShowRootPasswordToggle={args.onShowRootPasswordToggle}
            isPrepaid={isPrepaid}
            hasPrepaidDeploymentExpired={hasPrepaidDeploymentExpired}
            isDeploymentPaused={!!args.deployment.is_paused}
            copiedRootPassword={args.rootPasswordCopied}
            regionId={args.deployment.region_id}
            deployment_id={args.deployment.id || ""}
            deployment_url={args.deployment.url || ""}
            status={args.deployment.status}
            certificates={args.deployment.certificates}
            ipallowlistId={args.deployment.ipallowlist_id}
            onResumeDeployment={args.onResumeDeployment}
            provider={args.provider}
            deployment={args.deployment}
          />
        </SectionContent>
      </Section>

      <OverviewStatsView
        {...args}
        isPrepaid={isPrepaid}
        hasPrepaidDeploymentExpired={hasPrepaidDeploymentExpired}
        isDeploymentPaused={!!args.deployment.is_paused}
        version={args.deployment.version}
        custom_image={args.deployment.custom_image}
      />

      {!!phase && !!args.replicationDetails && !isEmpty(args.replicationDetails) && !isMigrationCompletedMoreThanAWeekAgo && (
        <Section>
          <SectionHead>
            <SectionHeader title="Migration status" />
          </SectionHead>
          <SectionContent>
            <MigrationStatusView replicationDetails={args.replicationDetails || {}} />{" "}
          </SectionContent>
        </Section>
      )}
      {!is_free_tier && (
        <Section>
          <SectionHead>
            <SectionHeader title="Configuration" />
            <SectionButtons>
              {hasPrepaidUpdate && isDeploymentEditAllowed && (
                <DeploymentPausedModal
                  onResumeDeployment={args.onResumeDeployment}
                  onClick={args.onUpdatePrepaidDeployment}
                  trigger={<ContentActionButtonEdit primary disabled={args.processing} title="Update" icon="arrow down" />}
                />
              )}
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            {hasPrepaidUpdate && !isDeploymentPaused && (
              <Message icon info>
                <Icon name="arrow down" />
                <Message.Content>
                  <Message.Header>Update available</Message.Header>
                  <p className="para">An update is available for the configuration of this (prepaid) deployment.</p>
                  <p className="para">
                    Click <b>Update</b> to apply the changes to the configuration indicated below.
                  </p>
                </Message.Content>
              </Message>
            )}
            <ConfigurationView
              {...args}
              supportPlans={args.supportPlans || []}
              isDiskAlmostFull={args.isDiskAlmostFull}
              current={{
                model: args.deployment.model,
                node_size: currentNodeSize,
                cpu_size: currentCPUSize,
                servers: args.deployment.servers,
                supportPlanID: args.deployment.support_plan_id,
              }}
              update={
                hasPrepaidUpdate
                  ? {
                      model: prepaidModel,
                      node_size: prepaidNodeSize,
                      cpu_size: prepaidCPUSize,
                      servers: undefined,
                      supportPlanID: (args.prepaidDeployment || {}).support_plan_id,
                    }
                  : undefined
              }
              showSupportPlan={isPrepaid || args.tierHasSupportPlans}
              diskPerformanceID={args.deployment.disk_performance_id || ""}
            />
          </SectionContent>
        </Section>
      )}
      {!isEmpty(args.privateNetworkDetails) && (
        <PrivateNetworkSummaryView
          privateNetworkDetail={args.privateNetworkDetails || {}}
          onSubscriptionIDChange={args.onSubscriptionIDChange}
          onAlternateDNSNamesChange={args.onAlternateDNSNamesChange}
          onAWSPrincipalsChange={args.onAWSPrincipalsChange}
          onSupportClick={args.onClickSupportRequest}
          onProjectNamesChange={args.onGCPProjectNameChange}
          provider={args.provider}
        />
      )}
      {!hasCustomImage && (hasReplaceBy || hasUpgradeRecommendation) && (
        <Section>
          <SectionHead>
            <SectionHeader title={hasReplaceBy ? "Upgrade pending" : "Upgrade recommendation"} />
          </SectionHead>
          <SectionContent>
            <StrongSegment>
              <FloatRight>
                <Icon name="idea" size="huge" color={hasReplaceBy ? "red" : "olive"} />
              </FloatRight>
              {hasReplaceBy && (
                <div>
                  <p className="para">
                    This deployment will be upgraded automatically to version <strong>{replaceBy.version} </strong>
                    {!replaceByDate && <span>shortly</span>}
                    {!!replaceByDate && <span>{moment(replaceByDate).fromNow()}</span>}.
                  </p>
                  <p className="para">Reason: {replaceBy.reason}</p>
                </div>
              )}
              {!hasReplaceBy && hasUpgradeRecommendation && (
                <div>
                  <p className="para">
                    You are recommended to upgrade this deployment to version <strong>{upgradeRecommendation.version}</strong>.
                  </p>
                  <p className="para">Reason: {upgradeRecommendation.reason}</p>
                </div>
              )}
            </StrongSegment>
          </SectionContent>
        </Section>
      )}
    </div>
  );
};

export default OverviewTabContent;
