//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton, InputControl, SingleSelectControl, SliderControl, TextareaControl } from "@arangodb/ui";
import { Button, Grid, ModalFooter, Text } from "@chakra-ui/react";
import { Form, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { NotebookModel } from "../../../api/notebook/v1/notebook";
import { NotebookFormValues } from "./CreateNotebookView";

export const CreateNotebookForm = ({
  notebookModels,
  onClose,
  isLoadingNotebookModels,
}: {
  notebookModels: NotebookModel[];
  onClose: () => void;
  isLoadingNotebookModels: boolean;
}) => {
  const { setFieldValue, isSubmitting, values } = useFormikContext<NotebookFormValues>();
  const memoizedNotebookModelOptions = useMemo(
    () =>
      notebookModels.map((model) => ({
        label: `${model.name} (${model.cpu} CPU unit(s) and ${model.memory}GB memory)`,
        value: model.id || "",
      })),
    [notebookModels]
  );

  const hasNotebookModels = !!notebookModels.length;
  const currentModelId = values.modelId;

  const {
    initial_disk_size: currentInitialDiskSize = 1,
    max_disk_size: maxDiskSize = 1,
    min_disk_size: minDiskSize = 1,
  } = notebookModels.find((model) => model.id === currentModelId) || {};

  useEffect(() => {
    setFieldValue("diskSize", currentInitialDiskSize);
  }, [currentModelId]);

  return (
    <Grid as={Form} gap="4" templateColumns="1fr">
      <InputControl
        isRequired
        label="Name"
        name="name"
        inputProps={{
          placeholder: "Give your notebook a recognizable name",
        }}
      />

      <TextareaControl name="description" label="Description" inputProps={{ placeholder: "Short description" }} />

      <SingleSelectControl
        label="Model"
        isRequired
        name="modelId"
        isDisabled={isLoadingNotebookModels || !hasNotebookModels}
        selectProps={{
          isLoading: isLoadingNotebookModels,
          options: memoizedNotebookModelOptions,
          placeholder: "Select Model",
          noOptionsMessage: () => <Text>No models found</Text>,
        }}
        tooltip="Specifies the amount of CPU units and the memory allocated to this notebook."
      />

      <SliderControl
        isRequired
        min={minDiskSize}
        max={maxDiskSize}
        label="Disk Size"
        name="diskSize"
        textAlign="center"
        maxText="GB"
        minText="GB"
        sliderValueText="GB"
        sliderProps={{
          width: "90%",
          marginTop: "8",
          step: 1,
        }}
      />

      <ModalFooter gap="4" marginBottom="2">
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <FormButton colorScheme="green" alignContent="center" isLoading={isSubmitting} autoFocus isDisabled={!hasNotebookModels}>
          Create Notebook
        </FormButton>
      </ModalFooter>
    </Grid>
  );
};
