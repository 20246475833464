//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchOrganizationDeployments = ({
  pageNumber = 0,
  pageSize = 10,
  keepPreviousData = true,
}: {
  pageNumber: number;
  pageSize: number;
  keepPreviousData?: boolean;
}) => {
  const { selectedOrganization } = useDashboardContext();

  const fetchOrganizationDeployments = async () => {
    return await apiClients.dataClient.ListDeploymentsByFilter({
      organization_id: selectedOrganization.id,
      options: {
        page: pageNumber,
        page_size: pageSize,
      },
    });
  };

  return useQuery({
    queryKey: ["organizationDeployments", selectedOrganization.id, pageNumber, pageSize],
    queryFn: () => fetchOrganizationDeployments(),
    enabled: !!selectedOrganization.id,
    keepPreviousData,
  });
};
