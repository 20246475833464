//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { ListActionDownload, Loading, PagingButtons, Section, SectionHead, SectionHeader, UnderlinedH3 } from "../../../ui/lib";
import { Card, Grid } from "semantic-ui-react";
import moment from "moment";
import { CreditUsageReport } from "../../../api/credits/v1/credits";
import { Box } from "../../../ui/_box";
import usePagination from "../../../ui/hooks/usePagination";
import { RenderGuard } from "../../../util/RenderGuard";
import { useToast } from "@chakra-ui/react";
import { useFetchCreditUsageReports } from "./useFetchCreditsBundlesReports";
import useErrorToast from "../../../util/hooks/useErrorToast";
import apiClients from "../../../api/apiclients";
import Downloader from "js-file-downloader";

export const CreditsBundleConsumptionList = () => {
  const toast = useToast();
  const [downloadingReportId, setDownloadingReportId] = useState<string>("");
  const { paginationObject, setNextPage, setPreviousPage } = usePagination();

  const { data, isLoading, error } = useFetchCreditUsageReports();
  const usageItems = data?.items || [];
  // If the error status is 429, do not show the error since it's being fixed at OAS-10077
  useErrorToast({ errorMessage: !!error && error?.status !== 429 ? "Error getting the credits usage report for this month" : undefined });

  if (!usageItems?.length) {
    return null;
  }

  const handleDownloadClick = async (item: CreditUsageReport) => {
    setDownloadingReportId(item.id || "");

    try {
      const stream = await apiClients.creditBundleClient.GetCreditUsageReportPDF(
        {
          id: item.id || "",
        },
        async (evt) => {
          const content = evt.message?.contents;
          if (content) {
            const decoded = atob(content);
            const bytes = new Array(decoded.length);
            for (let i = 0; i < decoded.length; ++i) {
              bytes[i] = decoded.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], { type: "application/pdf" });
            await new Downloader({
              url: window.URL.createObjectURL(blob),
              filename: evt.message?.filename || "report.pdf",
            });
          }
        }
      );

      await stream.closed;
    } catch (error) {
      toast({
        title: "Error downloading the report",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloadingReportId("");
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Credits bundle consumption">
          <PagingButtons
            page={paginationObject.page}
            count={usageItems.length}
            onNextPage={setNextPage}
            onPreviousPage={setPreviousPage}
            size="mini"
            pageSize={paginationObject.pageSize}
          />
        </SectionHeader>
      </SectionHead>
      <Box padding={"32px 0"}>
        <RenderGuard renderIf={isLoading}>
          <Loading />
        </RenderGuard>
        <RenderGuard renderIf={!isLoading}>
          <Card.Group itemsPerRow="1">
            {usageItems.map((item) => {
              return (
                <Card>
                  <Card.Content>
                    <Grid width="16">
                      <Grid.Column width="4">
                        <UnderlinedH3>Number</UnderlinedH3>
                        <div>{item.amount}</div>
                      </Grid.Column>
                      <Grid.Column width="4">
                        <UnderlinedH3>Date</UnderlinedH3>
                        <div>{moment(item.created_at).format("MMM DD, YYYY")}</div>
                      </Grid.Column>
                      <Grid.Column width="4">
                        <UnderlinedH3>Amount</UnderlinedH3>
                        <div>{item.closing_balance}</div>
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <ListActionDownload
                          loading={downloadingReportId === item.id}
                          disabled={downloadingReportId === item.id}
                          onClick={() => handleDownloadClick(item)}
                        />
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        </RenderGuard>
      </Box>
    </Section>
  );
};
