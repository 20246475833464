//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ExampleDatasetInstallation } from "../../api/lib";

export const useExampleDatasetInstallationStatus = ({ installation }: { installation: ExampleDatasetInstallation | undefined }) => {
  const { status: installationStatus = {}, is_deleted: isUninstalling } = installation || {};
  const { is_available: isAvailable, is_failed: isFailed, database_name: databaseName, demo_url: demoUrl } = installationStatus;

  const hasInstallation = !!installation;
  const hasDemo = !!isAvailable && !isUninstalling && !!demoUrl;
  const isCreatingDatabase = hasInstallation && !isAvailable && !isFailed;
  const isInstalling = hasInstallation && !isAvailable && !isFailed && !!databaseName;
  const isBusy = isCreatingDatabase || isInstalling || isUninstalling;

  const getLoadingText = () => {
    if (isInstalling) {
      return "Installing";
    }
    if (isCreatingDatabase) {
      return "Creating DB";
    }
    return "Getting ready";
  };

  const loadingText = getLoadingText();

  return { hasInstallation, databaseName, loadingText, isUninstalling, isBusy, hasDemo, demoUrl };
};
