//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Button, Card, CardBody, CardFooter, CardHeader, Flex, Grid, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../ui/_section";
import { useExampleDatasetInstallationStatus } from "../../util/hooks/useExampleDatasetInstallationStatus";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useFetchExamplesInstallationsList } from "../example/useFetchExamplesInstallationsList";
import { NetworkXCard } from "./NetworkXCard";

const ExamplesCard = () => {
  const history = useHistory();
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "", project_id: projectId = "", status: deploymentStatus = {} } = deployment;
  const { endpoint_default: deploymentEndpoint = "" } = deploymentStatus;
  const { data: installationsList, isLoading: isLoadingExamplesInstallationsList } = useFetchExamplesInstallationsList();

  const installation = installationsList?.items?.[0];

  const { hasInstallation, databaseName, loadingText, isUninstalling, isBusy } = useExampleDatasetInstallationStatus({ installation });

  const isLoadingEndpoint = isLoadingExamplesInstallationsList || isBusy || !deploymentEndpoint;

  const deploymentPath = Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId);

  const onOpenEndpoint = () => {
    if (!deployment.status?.endpoint) {
      return;
    }

    if (!databaseName) {
      return window.open(deploymentEndpoint);
    }

    const url = new URL(deploymentEndpoint);
    url.port = "";
    url.pathname = `_db/${databaseName}`;
    window.open(url);
  };

  return (
    <Card color="gray.950" boxShadow="md" borderWidth="1px" borderColor="gray.100">
      <CardHeader>
        <Heading size="md">New to ArangoDB?</Heading>
      </CardHeader>
      <CardBody>
        <Flex flexDirection="column" gap={4}>
          <Text>Explore its features with a built-in dataset. The airports-flights database is a great way to start learning about graph databases.</Text>
          {!hasInstallation ? (
            <Button alignSelf="center" isLoading={isLoadingExamplesInstallationsList} loadingText="Loading" isDisabled colorScheme="green">
              Sample not available
            </Button>
          ) : isUninstalling ? (
            <Button alignSelf="center" isLoading loadingText="Uninstalling" colorScheme="green" />
          ) : (
            <Button alignSelf="center" isLoading={isLoadingEndpoint} loadingText={loadingText} colorScheme="green" onClick={onOpenEndpoint}>
              Open sample database
            </Button>
          )}
        </Flex>
      </CardBody>
      <CardFooter>
        <Text>
          Do you want to discover more use cases? We have you covered. Open{" "}
          <Link
            color="blue.600"
            _hover={{ color: "blue.700", textDecoration: "underline" }}
            onClick={() => history.push(`${deploymentPath}#examples`)}
            textDecoration="underline"
            fontWeight="bold"
          >
            Examples
          </Link>{" "}
          and install any of the sample datasets. Each dataset is installed into its own database and has a guide with detailed instructions.
        </Text>
      </CardFooter>
    </Card>
  );
};

const LoadDataCard = () => {
  const history = useHistory();
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "", project_id: projectId = "" } = deployment;

  const deploymentPath = Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId);

  return (
    <Card color="gray.950" boxShadow="md" borderWidth="1px" borderColor="gray.100">
      <CardHeader>
        <Heading size="md">Ready to load your data?</Heading>
      </CardHeader>
      <CardBody alignItems="center" display="flex" flexDirection="column" gap={8}>
        <Text>
          Use the{" "}
          <Link
            color="blue.600"
            _hover={{ color: "blue.700", textDecoration: "underline" }}
            onClick={() => history.push(`${deploymentPath}#dataloader`)}
            fontWeight="bold"
            textDecoration="underline"
          >
            Data Loader
          </Link>{" "}
          to easily transform your CSV files into a named graph and corresponding node/edge collections.
        </Text>
        <Text>
          Want to load data from an existing ArangoDB instance or other files? Use the{" "}
          <Link
            color="blue.600"
            _hover={{ color: "blue.700", textDecoration: "underline" }}
            onClick={() => history.push(`${deploymentPath}#dataloader`)}
            fontWeight="bold"
            textDecoration="underline"
          >
            client tools
          </Link>{" "}
          to seamlessly import your data.
        </Text>
      </CardBody>
    </Card>
  );
};

const LearningContentSections = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Quick start" />
      </SectionHead>

      <SectionContent>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <NetworkXCard />
          <ExamplesCard />
          <LoadDataCard />
        </Grid>
      </SectionContent>
    </Section>
  );
};

export { LearningContentSections };
