import { CheckIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { Flex, Icon, Spinner, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdOutlinePriorityHigh, MdPause } from "react-icons/md";
import { Deployment } from "../../../api/lib";

export const DeploymentStatusCell = ({ deployment }: { deployment: Deployment }) => {
  const { status } = deployment;

  const isPrepaid = !!deployment.prepaid_deployment_id;
  if (deployment.is_deleted) {
    return (
      <Flex gap="2" alignItems="center">
        <Text>Deleting</Text>
        <Spinner size="xs" />
      </Flex>
    );
  }
  if (status) {
    if (!status.bootstrapped) {
      return (
        <Tooltip hasArrow label={`Bootstrapping: ${status.description}`} placement="top">
          <Flex gap="2" alignItems="center">
            <Text>Bootstrapping</Text>
            <Spinner size="xs" />
          </Flex>
        </Tooltip>
      );
    }
    if (status.description === "Hibernated") {
      return (
        <Flex gap="2" alignItems="center">
          <Text>Hibernated</Text>
          <Icon as={MdPause} />
        </Flex>
      );
    }

    if (deployment.is_paused) {
      return (
        <span>
          {isPrepaid ? (
            <Tooltip label="The deployment has been paused because your contract has ended. Please contact your account manager for further action.">
              <Flex gap="2" alignItems="center">
                <Text>Paused</Text>
                <Icon as={MdPause} />
              </Flex>
            </Tooltip>
          ) : (
            <Flex gap="2" alignItems="center">
              <Text>Paused</Text>
              <Icon as={MdPause} />
            </Flex>
          )}
        </span>
      );
    }

    if (status.upgrading) {
      return (
        <Flex gap="2" alignItems="center">
          <Text>Upgrading</Text>
          <Spinner size="xs" />
        </Flex>
      );
    }

    if (status.ready) {
      return (
        <Flex gap="2" alignItems="center">
          <Text>OK</Text>
          <Icon color="green" as={CheckIcon} />
        </Flex>
      );
    }

    if (!status.created) {
      return (
        <Tooltip hasArrow label={`Creating: ${status.description}`} placement="top">
          <Flex gap="2" alignItems="center">
            <Text>Creating</Text>
            <Spinner size="xs" />
          </Flex>
        </Tooltip>
      );
    }

    // Any failed server results in Error
    const hasFailedServers = status.servers && status.servers.some((server) => !!server.failed);
    if (hasFailedServers) {
      return (
        <Tooltip hasArrow label={`Error: ${status.description}`} placement="top">
          <Flex color="red" gap="2" alignItems="center">
            <Text>Error</Text>
            <Icon as={MdOutlinePriorityHigh} />
          </Flex>
        </Tooltip>
      );
    }
    return (
      <Tooltip hasArrow label={`Not Ready: ${status.description}`} placement="top">
        <Flex color="red" gap="2" alignItems="center">
          <Text>Not Ready</Text>
          <Icon as={MdOutlinePriorityHigh} />
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Flex color="red" gap="2" alignItems="center">
      <Text>Unknown</Text>
      <Icon as={QuestionOutlineIcon} />
    </Flex>
  );
};
