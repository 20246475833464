//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Progress, Text, VStack, Box, Flex, Heading } from "@chakra-ui/react";
import { CreditBundle, CreditBundlesList } from "../../../api/credits/v1/credits";
import moment from "moment";

const CreditsPurchaseTable = ({ creditBundles, hasExpiredCredits }: { creditBundles: CreditBundle[]; hasExpiredCredits?: boolean }) => {
  const getPercent = (creditsPurchased: number, creditsRemaining: number) => {
    if (creditsPurchased - creditsRemaining === 0) return 0;

    return (creditsRemaining / creditsPurchased) * 100;
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th whiteSpace="nowrap">Details</Th>
          <Th whiteSpace="nowrap">Purchased On</Th>
          <Th whiteSpace="nowrap">Validity</Th>
          <Th whiteSpace="nowrap">Purchased Credits</Th>
          <Th whiteSpace="nowrap">Used Credits</Th>
          <Th whiteSpace="nowrap">Remaining Credits</Th>
          <Th>Progress</Th>
        </Tr>
      </Thead>
      <Tbody>
        {creditBundles.map((ccBundle) => {
          return (
            <Tr key={ccBundle.id}>
              <Td>
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold">ArangoGraph Credits</Text>
                  <Text fontSize="sm" color="gray.500">
                    {ccBundle.id}
                  </Text>
                </VStack>
              </Td>
              <Td>{moment(ccBundle.purchased_at).format("MMM DD, YYYY")}</Td>
              <Td>
                {moment(ccBundle.valid_from).format("MMM DD, YYYY")} - {moment(ccBundle.valid_until).format("MMM DD, YYYY")}
              </Td>
              <Td>{Number(ccBundle.credits_purchased).toFixed(2)}</Td>
              <Td>
                {ccBundle.credits_purchased && ccBundle.credits_remaining
                  ? (Number(ccBundle.credits_purchased) - Number(ccBundle.credits_remaining)).toFixed(2)
                  : "-"}
              </Td>
              <Td>{ccBundle.credits_remaining ? Number(ccBundle.credits_remaining).toFixed(2) : "-"}</Td>
              {!!hasExpiredCredits ? (
                <Td>Credits expired</Td>
              ) : (
                <Td>
                  {Number(ccBundle.credits_purchased) - Number(ccBundle.credits_remaining) > 0 ? (
                    <Progress value={getPercent(Number(ccBundle.credits_purchased), Number(ccBundle.credits_remaining))} size="sm" colorScheme="green" />
                  ) : (
                    <Box>Fully available</Box>
                  )}
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export const ActiveCreditsBundlePurchaseView = ({ creditBundles }: { creditBundles: CreditBundlesList }) => {
  const { items = [] } = creditBundles;

  const activeCreditBundles = items.filter((item) => {
    return moment(item.valid_until).isAfter(moment()) && Number(item.credits_remaining) > 0;
  });

  return (
    <Flex direction="column">
      <Heading fontSize="2xl">Available Credits & History</Heading>
      <Flex direction="column" marginY={4}>
        <CreditsPurchaseTable creditBundles={activeCreditBundles} />
      </Flex>
    </Flex>
  );
};

export const ExpiredCreditsBundlePurchaseHistory = ({ creditBundles }: { creditBundles: CreditBundlesList }) => {
  const { items = [] } = creditBundles;

  const expiredCreditBundle = items.filter((item) => {
    return moment(item.valid_until).isBefore(moment()) || Number(item.credits_remaining) === 0;
  });

  if (!expiredCreditBundle.length) return null;

  return (
    <Flex direction="column" gap={4}>
      <Heading fontSize="2xl">Credit Purchase History</Heading>

      <CreditsPurchaseTable creditBundles={expiredCreditBundle} hasExpiredCredits />
    </Flex>
  );
};
