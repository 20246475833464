//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";

const ProjectNotFound = () => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
      <Heading>Project not found</Heading>
      <Text>
        Looks like the project with ID{" "}
        <Text fontWeight="bold" as="span">
          {projectId}
        </Text>{" "}
        does not exist.
      </Text>
      <Text>It might have been deleted recently, please check the list of your existing projects.</Text>
      <Button colorScheme="green" onClick={() => history.push(Routes.dashboard_projects)}>
        Go back to my projects
      </Button>
    </Box>
  );
};

export { ProjectNotFound };
