//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";

const useErrorToast = ({ errorMessage }: { errorMessage: string | undefined }) => {
  const toast = useToast();

  useEffect(() => {
    if (errorMessage) {
      toast({
        title: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [errorMessage]);
};

export default useErrorToast;
