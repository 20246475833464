//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { Icon, Popup, Statistic } from "semantic-ui-react";

import { Section, SectionContent, SectionHead, SectionHeader, StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { DeploymentStatusView } from "./DeploymentStatusView";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { CustomImageTag, IOverviewViewArgs, StyledStatisticGroup } from "./OverviewViews";

const FreeTierStats = ({ isPrepaid }: Partial<IOverviewViewArgs>) => {
  const { selectedOrganization } = useDashboardContext();
  const { deployment } = useDeploymentStore();
  const {
    expiration = {},
    is_deleted: isDeleted,
    version,
    custom_image: customImage,
    created_at: createdAt,
    prepaid_deployment_ends_at: prepaidDeploymentEndsAt,
    deleted_at: deletedAt,
    status: deploymentStatus,
  } = deployment;
  const showExpiresAt = !isDeleted && !!expiration.expires_at;

  const isFreeTier = selectedOrganization.tier && selectedOrganization.tier.id == "free";

  const expiryString = expiration.expires_at ? `${moment(expiration.expires_at).fromNow()}` : "";

  if (!isFreeTier) {
    return null;
  }

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Status summary" />
      </SectionHead>
      <SectionContent>
        <StyledStatsSegment>
          <StatisticsContentGroup>
            <StyledStatisticGroup>
              <Statistic size="mini">
                <Statistic.Label>Status</Statistic.Label>
                <Statistic.Value>
                  <DeploymentStatusView icons={false} deployment={deployment} status={deploymentStatus} />
                </Statistic.Value>
              </Statistic>

              {!isPrepaid && showExpiresAt && (
                <Statistic size="mini">
                  <Statistic.Label>
                    Expires
                    {expiration.reason && <Popup content={expiration.reason} trigger={<Icon name="info circle" className="secondary-text" />} />}
                  </Statistic.Label>
                  <Statistic.Value>{expiration.expires_at ? expiryString : "-"}</Statistic.Value>
                </Statistic>
              )}

              <Statistic size="mini">
                <Statistic.Label>Created</Statistic.Label>
                <Statistic.Value>{createdAt ? moment(createdAt).fromNow() : "-"}</Statistic.Value>
              </Statistic>

              {isPrepaid && (
                <Statistic size="mini">
                  <Statistic.Label>Contract Ends</Statistic.Label>
                  <Statistic.Value>{moment(prepaidDeploymentEndsAt).fromNow()}</Statistic.Value>
                </Statistic>
              )}

              {isDeleted && (
                <Statistic size="mini">
                  <Statistic.Label>Deleted</Statistic.Label>
                  <Statistic.Value>{deletedAt ? moment(deletedAt).fromNow() : "-"}</Statistic.Value>
                </Statistic>
              )}

              <Statistic size="mini">
                <Statistic.Label>Version</Statistic.Label>
                <Statistic.Value>
                  {version}
                  {!!customImage && <CustomImageTag>{customImage}</CustomImageTag>}
                </Statistic.Value>
              </Statistic>
            </StyledStatisticGroup>
          </StatisticsContentGroup>
        </StyledStatsSegment>
      </SectionContent>
    </Section>
  );
};

export { FreeTierStats };
