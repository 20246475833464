//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import { useDashboardContext } from "../../DashboardContextProvider";
import apiClients from "../../../api/apiclients";

export const useFetchCreditBundles = () => {
  const { selectedOrganization } = useDashboardContext();

  const fetchCreditBundles = async () => {
    const creditBundles = await apiClients.creditBundleClient.ListCreditBundles({
      organization_id: selectedOrganization?.id || "",
      exclude_expired: false,
    });
    return creditBundles;
  };

  return useQuery([selectedOrganization?.id, "creditBundles"], fetchCreditBundles);
};
