//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Timestamp } from "../api/googleTypes";

export const DateDisplay = ({ date }: { date: string | Timestamp | undefined }) => {
  return (
    <Tooltip hasArrow placement="top" label={moment(date).format("MMM Do YYYY, h:mm:ss A")}>
      {moment(date).fromNow()}
    </Tooltip>
  );
};
