//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import { useDashboardContext } from "../../DashboardContextProvider";
import apiClients from "../../../api/apiclients";

export const useFetchCreditDebt = () => {
  const { selectedOrganization } = useDashboardContext();

  const fetchCreditDebt = async () => {
    const creditDebt = await apiClients.creditBundleClient.GetOrganizationCreditDebt({
      id: selectedOrganization?.id || "",
    });
    return creditDebt;
  };

  return useQuery([selectedOrganization?.id, "creditDebt"], fetchCreditDebt);
};
