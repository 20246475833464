//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import { useDashboardContext } from "../../DashboardContextProvider";
import apiClients from "../../../api/apiclients";

export const useFetchCreditBundleUsageProjection = () => {
  const { selectedOrganization } = useDashboardContext();

  const fetchCreditBundleUsageProjection = async () => {
    const PROJECTIONS_FOR_X_MONTHS = 12;
    const creditBundlesDebt = await apiClients.creditBundleClient.GetCreditBundleUsageProjection({
      organization_id: selectedOrganization?.id || "",
      projections_limit: PROJECTIONS_FOR_X_MONTHS,
    });
    return creditBundlesDebt;
  };

  return useQuery([selectedOrganization?.id, "creditBundleUsageProjection"], fetchCreditBundleUsageProjection);
};
