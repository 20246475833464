//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../../api/apiclients";
import useOrganisationStore from "../../../util/storage/OrganisationStore";

const fetchProject = async (id: string | undefined) => {
  const projectResponse = await apiClients.resourceManagerClient.GetProject({ id });
  return projectResponse;
};

export const useProject = (projectId: string | undefined) => {
  const setStoreProject = useOrganisationStore((state) => state.setProject);
  const storedProject = useOrganisationStore((state) => (projectId ? state.getProject(projectId) : undefined));

  return useQuery(["project", projectId], () => fetchProject(projectId), {
    enabled: !!projectId, // Only run the query if projectId is truthy
    initialData: storedProject,
    onSuccess: (data) => {
      if (!data) return;
      setStoreProject(data);
    },
  });
};
