//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Button, Flex, Text, useClipboard } from "@chakra-ui/react";
import { CopyIcon, CheckIcon } from "@chakra-ui/icons";
import apiClients from "../../api/apiclients";
import { useToast } from "@chakra-ui/react";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDeploymentPermissions } from "../deployment/useDeploymentPermissions";

const CopyRootPassword = ({ copyPasswordText }: { copyPasswordText?: string | React.ReactNode }) => {
  const [rootPassword, setRootPassword] = useState<string>("");
  const { onCopy, hasCopied: hasCopiedRootPassword } = useClipboard(rootPassword, 5000);
  const { deployment } = useDeploymentStore();
  const { isGetRootPasswordAllowed } = useDeploymentPermissions();
  const toast = useToast();

  const onCopyRootPassword = async () => {
    onCopy();
    toast({
      title: `Root password copied to clipboard`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const getRootPassword = async () => {
    try {
      const { password } = await apiClients.dataClient.GetDeploymentCredentials({ deployment_id: deployment.id });
      setRootPassword(password || "");
    } catch {
      toast({
        title: `Failed to get the root password`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isGetRootPasswordAllowed) {
      getRootPassword();
    }
  }, [isGetRootPasswordAllowed]);

  if (!isGetRootPasswordAllowed) {
    return null;
  }

  return (
    <Button
      variant="link"
      onClick={onCopyRootPassword}
      fontWeight="normal"
      fontSize="normal"
      width="min-content"
      height="10px"
      textDecoration="underline"
      color="gray.800"
      _hover={{
        color: "gray.950",
      }}
    >
      <Flex alignItems="center" gap="2">
        <Text>{copyPasswordText || "Copy root password"}</Text>
        {hasCopiedRootPassword ? <CheckIcon color="green" /> : <CopyIcon />}
      </Flex>
    </Button>
  );
};

export { CopyRootPassword };
