//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { HomeTemplateView } from "../home/HomeTemplate";
import { IWithSignupModalProps, withSignupModal } from "../home/SignupModalView";

import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Button, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";

interface IAuth {
  accessDeniedReason: string;
  isAuthenticated: () => boolean;
  login: () => void;
  signup: () => void;
  logout: () => void;
}

interface IEmailNeedsVerificationProps extends IWithSignupModalProps {
  auth: IAuth;
}

const EmailNeedsVerification = (props: IEmailNeedsVerificationProps) => {
  useEffect(() => {
    props.tracking.trackEmailNeedsVerification();
  }, []);

  return (
    <HomeTemplateView
      {...props}
      isAuthenticated={props.auth.isAuthenticated()}
      onClickLogin={props.auth.login}
      onSignup={props.auth.signup}
      onClickLogout={props.auth.logout}
    >
      <Flex maxWidth="960px" margin="0 auto" direction="column" gap={4}>
        <Link href="/">
          <Image maxWidth="500px" src={logo} alt="ArangoGraph" />
        </Link>
        <Heading>Verify your email address</Heading>

        <Text>Your email address has not been verified yet.</Text>
        <Text>
          Please check your inbox for an email with subject{" "}
          <Text as="span" fontWeight="bold">
            Verify your email address for ArangoGraph
          </Text>
          .
        </Text>
        <Text>
          Click on the{" "}
          <Text as="span" fontWeight="bold">
            Verify my email address
          </Text>{" "}
          link in that email. That will bring you back to ArangoGraph.
        </Text>
        <Text>
          If you do not receive a verification email within a few minutes, please check your spam box. If you do not receive such an email, please create a
          support request using the{" "}
          <Text as="span" fontWeight="bold">
            Request help
          </Text>{" "}
          button in the top of this page.
        </Text>
        <Button alignSelf="flex-start" onClick={props.auth.logout}>
          Log out
        </Button>
      </Flex>
    </HomeTemplateView>
  );
};

export default withSignupModal(EmailNeedsVerification);
