//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { CreditBundlesList } from "../../../api/credits/v1/credits";
import moment from "moment";
import { Card, Text } from "@chakra-ui/react";

export const CreditsStats = ({ creditBundle }: { creditBundle: CreditBundlesList }) => {
  const [purchased, setPurchased] = React.useState<number>(0);
  const [used, setUsed] = React.useState<number>(0);

  const getAvailableCreditsFromPurchasedBundles = (creditBundlesPurchased: CreditBundlesList) => {
    const { items = [] } = creditBundlesPurchased;

    const activeCreditBundles = items.filter((item) => {
      return moment(item.valid_until).isAfter(moment());
    });

    const totalPurchased = activeCreditBundles.reduce((acc, item) => {
      return acc + (item.credits_purchased || 0);
    }, 0);

    const totalRemaining = activeCreditBundles.reduce((acc, item) => {
      return acc + (item.credits_remaining || 0);
    }, 0);

    return { totalPurchased, totalRemaining };
  };

  useEffect(() => {
    const { totalPurchased, totalRemaining } = getAvailableCreditsFromPurchasedBundles(creditBundle);
    setPurchased(totalPurchased);
    setUsed(totalPurchased - totalRemaining);
  }, [creditBundle]);

  return (
    <Card padding="30px" color="initial" marginY={4}>
      <Text fontWeight="bold" fontSize="2rem">
        {(purchased - used).toFixed(2)}
      </Text>
      <Text fontSize="1rem" fontWeight="bold" textTransform="uppercase">
        Remaining Credits
      </Text>
    </Card>
  );
};
