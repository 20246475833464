//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Card, Spinner, Text } from "@chakra-ui/react";

const SectionSpinner = ({ loadingText }: { loadingText?: string }) => {
  return (
    <Card padding="30px" color="initial" marginY={4} alignItems="center" gap={4}>
      <Spinner color="green.600" />
      {loadingText && <Text>{loadingText}</Text>}
    </Card>
  );
};

export { SectionSpinner };
