//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment, { DurationInputArg1, DurationInputArg2 } from "moment";
import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from "recharts";
import { CreditBundleUsage } from "../../../api/credits/v1/credits";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useDashboardContext } from "../../DashboardContextProvider";
import { Alert, AlertIcon, Box, Button, ButtonGroup, Flex, Heading, Tooltip, Text } from "@chakra-ui/react";
import useErrorToast from "../../../util/hooks/useErrorToast";
import { SectionSpinner } from "../../../components/SectionSpinner";
import { useGetCreditUsageForAllPages } from "./useGetCreditUsageForAllPages";

export const CreditsGraphActions = ({
  onChange,
  disabled,
  loading,
}: {
  onChange: (start: string, end: string) => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const RANGES = {
    MONTH_1: 0,
    MONTH_3: 1,
    YEAR_1: 2,
  };

  const [usageDateRange, setUsageDateRange] = useState(RANGES.MONTH_1);

  const getDataForRange = (amount: DurationInputArg1, unit: DurationInputArg2) => {
    const startDate = moment().subtract(amount, unit).format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    onChange(startDate, endDate);
  };

  useEffect(() => {
    const start = moment().subtract(1, "month").format("YYYY-MM-DD");
    const end = moment().format("YYYY-MM-DD");
    onChange(start, end);
  }, []);

  return (
    <Flex justify="flex-start" padding={"32px 0"}>
      <ButtonGroup isAttached>
        <Tooltip label="This will show the credit usage for the last 30 days." borderRadius="md" hasArrow>
          <Button
            disabled={disabled}
            isLoading={loading && usageDateRange === RANGES.MONTH_1}
            onClick={() => {
              getDataForRange(1, "month");
              setUsageDateRange(0);
            }}
            colorScheme={usageDateRange === RANGES.MONTH_1 ? "green" : "gray"}
          >
            1 month
          </Button>
        </Tooltip>

        <Tooltip label="This will show the credit usage for the last 90 days." borderRadius="md" hasArrow>
          <Button
            disabled={disabled}
            isLoading={loading && usageDateRange === RANGES.MONTH_3}
            onClick={() => {
              getDataForRange(3, "month");
              setUsageDateRange(1);
            }}
            colorScheme={usageDateRange === RANGES.MONTH_3 ? "green" : "gray"}
          >
            3 months
          </Button>
        </Tooltip>

        <Tooltip label="This will show the credit usage for the last 12 months." borderRadius="md" hasArrow>
          <Button
            disabled={disabled}
            isLoading={loading && usageDateRange === RANGES.YEAR_1}
            onClick={() => {
              getDataForRange(1, "year");
              setUsageDateRange(2);
            }}
            colorScheme={usageDateRange === RANGES.YEAR_1 ? "green" : "gray"}
          >
            1 year
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};

const RateGraphTooltip = ({ active, payload }: { active?: boolean; payload?: Array<Payload<ValueType, NameType>> }) => {
  const { selectedOrganization } = useDashboardContext();

  if (!active || !payload || !payload.length) {
    return null;
  }

  const { usage, created_at } = payload[0].payload || [];
  return (
    <Box backgroundColor="white" padding={"10px"} borderRadius="md" boxShadow="0 0 4px 0 var(--grey-100)">
      <div>
        Organization:<b> {selectedOrganization?.name}</b>
      </div>
      <div>
        Usage:<b> {Number(usage).toFixed(2)}</b>
      </div>
      <div>
        Date:<b> {moment(created_at).format("MMM DD, YYYY")}</b>
      </div>
    </Box>
  );
};

export const CreditUsageGraphContainer = ({ data, isLoading }: { data: CreditBundleUsage[]; isLoading: boolean }) => {
  if (isLoading) {
    return <SectionSpinner loadingText="Fetching and aggregating data, please wait..." />;
  }

  if (!data.length) {
    return (
      <Alert itemType="info" borderRadius="md" variant="left-accent">
        <AlertIcon />
        No Usage data available
      </Alert>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)))} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" tickFormatter={(timestamp) => moment(timestamp).format("MMM DD, YYYY")} />
        <YAxis />
        <RechartsTooltip content={<RateGraphTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="usage" name="Credits Used" stroke="var(--green-950)" activeDot={{ r: 2 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const CreditsUsageGraph = () => {
  const { usageData, isLoading, getUsage, error } = useGetCreditUsageForAllPages();
  useErrorToast({ errorMessage: error });

  return (
    <Flex direction="column" gap={4}>
      <Heading fontSize="2xl">Credits Usage</Heading>

      <Box position="relative">
        <Text>The graph below displays your daily credit consumption over the selected timeframe, which may vary based on your active deployments.</Text>
        <CreditsGraphActions onChange={getUsage} loading={isLoading} disabled={isLoading || !usageData.length} />
        <CreditUsageGraphContainer data={usageData} isLoading={isLoading} />
      </Box>
    </Flex>
  );
};
