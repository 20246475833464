//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useState } from "react";
import apiClients from "../../../api/apiclients";
import { CreditBundleUsage } from "../../../api/credits/v1/credits";
import { ListOptions } from "../../../api/lib";
import { useAPIUtils } from "../../../util/hooks/useAPIUtils";
import { useDataAggregator } from "../../../util/hooks/useDataAggregator";
import { useDashboardContext } from "../../DashboardContextProvider";

const useGetCreditUsageForAllPages = (pageSizeLimit: number = 300) => {
  const { selectedOrganization } = useDashboardContext();
  const { fetchDataForAllPages } = useAPIUtils();
  const { getAggregatedSumByDate } = useDataAggregator();
  const [usageData, setUsageData] = useState<CreditBundleUsage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [usageCache, setUsageCache] = useState<{ [key: string]: CreditBundleUsage[] }>({});

  const getUsageDataFromRange = async (start: string, end: string, page: ListOptions) => {
    try {
      const { items = [] } = await apiClients.creditBundleClient.ListCreditBundlesUsage({
        organization_id: selectedOrganization?.id || "",
        starts_at: new Date(start),
        ends_at: new Date(end),
        options: {
          ...page,
        },
      });
      return { data: items };
    } catch (error) {
      return { error };
    }
  };

  const getUsage = async (start: string, end: string) => {
    setError(undefined);
    if (usageCache[start + end] && usageCache[start + end].length) {
      setUsageData(usageCache[start + end]);
      return;
    }

    setIsLoading(true);

    const { data = [], error } = await fetchDataForAllPages<CreditBundleUsage>(
      (page) => getUsageDataFromRange(start, end, { page, page_size: pageSizeLimit }),
      pageSizeLimit
    );

    if (error) {
      setIsLoading(false);
      setError("Unable to fetch usage data");
      return;
    }

    const usage = getAggregatedSumByDate<CreditBundleUsage>(
      data.map((item) => ({ ...item, usage: item.usage || 0 })),
      "created_at",
      "usage"
    );

    setUsageData(Object.values(usage));
    setUsageCache({ ...usageCache, [start + end]: Object.values(usage) });
    setIsLoading(false);
  };

  return { usageData, isLoading, error, getUsage };
};

export { useGetCreditUsageForAllPages };
