//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../../api/apiclients";
import useOrganisationStore from "../../../util/storage/OrganisationStore";

const fetchRegion = async (id: string) => {
  const regionResponse = await apiClients.platformClient.GetRegion({ id });
  return regionResponse;
};

export const useRegion = (regionId: string | undefined) => {
  const setStoreRegion = useOrganisationStore((state) => state.setRegion);
  const storedRegion = useOrganisationStore((state) => state.getRegion(regionId || ""));

  return useQuery(["region", regionId], () => fetchRegion(regionId || ""), {
    enabled: !!regionId, // Only run the query if regionId is truthy
    initialData: storedRegion,
    onSuccess: (data) => {
      if (!data) return;
      setStoreRegion(data);
    },
  });
};
