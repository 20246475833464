import { Button, Flex } from "@chakra-ui/react";
import React from "react";

export const Pagination = ({ totalPages, page, setPage }: { totalPages: number | undefined; page: number; setPage: (page: number) => void }) => {
  if (!totalPages || totalPages <= 1) {
    return <></>;
  }
  return (
    <Flex gap="2">
      {Array.from({ length: totalPages }).map((_, index) => (
        <Button
          size="sm"
          colorScheme={index === page ? "green" : "gray"}
          _disabled={{ cursor: "not-allowed" }}
          isDisabled={index === page}
          key={index}
          onClick={() => setPage(index)}
        >
          {index + 1}
        </Button>
      ))}
    </Flex>
  );
};
