import { Button, Spinner } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Deployment } from "../../../api/lib";
import { Routes } from "../../../routes";
import { useProject } from "./useProject";

export const DeploymentProjectCell = ({ deployment }: { deployment: Deployment }) => {
  // link to project
  const { data: project, isLoading } = useProject(deployment.project_id);
  if (isLoading) {
    return <Spinner size="xs" />;
  }
  return (
    <Button
      whiteSpace="normal"
      as={RouterLink}
      to={Routes.dashboard_projectWithId(project?.id || "")}
      variant="link"
      color="blue.600"
      size="sm"
      _hover={{
        color: "blue.700",
        textDecoration: "underline",
      }}
      justifyContent="flex-start"
    >
      {project?.name}
    </Button>
  );
};
