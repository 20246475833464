//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner, ReactTable, useSortableReactTable } from "@arangodb/ui";
import { Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { Deployment } from "../../../api/lib";
import { DateDisplay } from "../../../components/DateDisplay";
import { Pagination } from "../../../components/Pagination";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../../ui/lib";
import { useFetchOrganizationDeployments } from "../../newDeployment/useFetchOrganizationDeployments";
import { DeploymentNameCell } from "./DeploymentNameCell";
import { DeploymentProjectCell } from "./DeploymentProjectCell";
import { DeploymentRegionCell } from "./DeploymentRegionCell";
import { DeploymentStatusCell } from "./DeploymentStatusCell";

const columnHelper = createColumnHelper<Deployment>();

const TABLE_COLUMNS = [
  columnHelper.accessor("name", {
    header: "Name",
    id: "name",
    cell: (props) => {
      return <DeploymentNameCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("project_id", {
    header: "Project",
    id: "project_id",
    cell: (props) => {
      return <DeploymentProjectCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("region_id", {
    header: "Region",
    id: "region_id",
    cell: (props) => {
      return <DeploymentRegionCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("created_at", {
    header: "Created",
    id: "created_at",
    cell: (props) => {
      return <DateDisplay date={props.getValue()} />;
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    id: "status",
    cell: (props) => {
      return <DeploymentStatusCell deployment={props.row.original} />;
    },
  }),
];

export const DashboardDeploymentList = () => {
  const [page, setPage] = React.useState(0);
  const pageSize = 10;
  const {
    data: deploymentsList,
    isLoading,
    fetchStatus,
    isPreviousData,
  } = useFetchOrganizationDeployments({
    pageNumber: page,
    pageSize,
  });
  const totalUsed = deploymentsList?.budget?.used;
  const totalPages = totalUsed ? Math.ceil(totalUsed / pageSize) : 0;
  const tableInstance = useSortableReactTable({
    columns: TABLE_COLUMNS,
    data: deploymentsList?.items || [],
    enableSorting: false,
  });
  return (
    <Section>
      <SectionHead>
        <SectionHeader title={<span>Deployments</span>} />
      </SectionHead>
      <SectionContent>
        <PageSpinner isLoading={isLoading || (isPreviousData && fetchStatus === "fetching")} />
        <Flex direction="column" gap="2">
          <ReactTable
            table={tableInstance}
            tableProps={{
              variant: "striped",
            }}
          />
          <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        </Flex>
      </SectionContent>
    </Section>
  );
};
