//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button } from "semantic-ui-react";
import { CACertificate as ApiCACertificate, Project as ApiProject } from "../../api/lib";
import { DefaultAWSRegionID, DefaultRegionID } from "../../constants";
import { Box } from "../../ui/_box";
import { ErrorMessage, Processing } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import SelectAnyRegion from "./SelectAnyRegion";
import useCreateFirstDeployment from "./useCreateFirstDeployment";
import useCurrentTermsAndConditions from "./useCurrentTermsAndConditions";

export const FirstDeploymentMessage = ({
  defaultCertificate,
  project,
  onNewDeploymentCreated,
}: {
  defaultCertificate: ApiCACertificate | undefined;
  project: ApiProject | undefined;
  onNewDeploymentCreated: (id: string) => void;
}) => {
  const { selectedOrganization, isPerpetualFreeTrialAvailable } = useDashboardContext();
  const { termsAndConditions, selectedRegion, onRegionUpdated } = useCurrentTermsAndConditions();

  const { processing, errorMessage, createFirstDeployment } = useCreateFirstDeployment({
    certificateId: defaultCertificate?.id || "",
    projectId: project?.id || "",
    onNewDeploymentCreated,
    termsAndConditionsId: (termsAndConditions || {}).id || "",
    regionId: (selectedRegion || {}).id || "",
    isPerpetualFreeTrialAvailable,
  });
  const { eventSubscriptionManager, permissionCache } = useWithRefresh();

  return (
    <Box border="2px solid var(--gray-300)" borderRadius="6px" padding="20px 30px">
      <Processing active={processing} message="Creating deployment ..." />
      <Box textAlign="center" display="flex" direction="column" gap="24px" align="center">
        <Box display="flex" gap="12px">
          <Box>Let's create your first instance of ArangoDB</Box>
          <SelectAnyRegion
            organizationId={selectedOrganization.id || ""}
            inline
            defaultRegionId={isPerpetualFreeTrialAvailable ? DefaultAWSRegionID : DefaultRegionID}
            onRegionUpdated={onRegionUpdated}
            eventSubscriptionManager={eventSubscriptionManager}
            permissionCache={permissionCache}
            loading={false}
            isPerpetualFreeTrialAvailable={isPerpetualFreeTrialAvailable}
            isFreeDeployment
          />
        </Box>
        <Button primary content="Create your first instance" onClick={createFirstDeployment} disabled={!selectedRegion} />
      </Box>
      {errorMessage && <ErrorMessage message={errorMessage} active />}
    </Box>
  );
};
