//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { CreditDebt } from "../../../api/credits/v1/credits";
import { useDashboardContext } from "../../DashboardContextProvider";
import { Button, Card, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const CreditDebtView = ({ creditDebt }: { creditDebt: CreditDebt | undefined }) => {
  const { onClickSupportRequest } = useDashboardContext();

  if (!creditDebt?.amount) return null;

  return (
    <Card padding="30px" color="initial" marginY={4}>
      <Text fontWeight={"bold"} fontSize={"2rem"} color="red">
        {creditDebt?.amount?.toFixed(2) || 0}
      </Text>
      <Text fontSize={"1rem"} fontWeight={500}>
        You have run out of credits and are currently in debt, if new credits are not purchased soon then your deployments will be automatically shut down.{" "}
        <Button
          variant="link"
          onClick={onClickSupportRequest}
          fontWeight="normal"
          fontSize="normal"
          width="min-content"
          height="10px"
          textDecoration="underline"
          color="gray.800"
          _hover={{
            color: "gray.950",
          }}
        >
          <Text>
            Contact us <ExternalLinkIcon />
          </Text>
        </Button>{" "}
        to discuss this further.
      </Text>
    </Card>
  );
};
