import { Button } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Deployment } from "../../../api/lib";
import { Routes } from "../../../routes";

export const DeploymentNameCell = ({ deployment }: { deployment: Deployment }) => {
  const projectId = deployment.project_id;
  const deploymentId = deployment.id;
  return (
    <Button
      whiteSpace="normal"
      as={RouterLink}
      to={Routes.dashboard_project_deployment_detailsWithId(projectId || "", deploymentId || "")}
      variant="link"
      color="blue.600"
      size="sm"
      _hover={{
        color: "blue.700",
        textDecoration: "underline",
      }}
      justifyContent="flex-start"
    >
      {deployment.name}
    </Button>
  );
};
