//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { TopMenuBar } from "../../components/TopMenuBar";
import { Routes } from "../../routes";
import deploymentIcon from "../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/deployment.svg";
import { useDashboardContext } from "../DashboardContextProvider";

export const NewDeploymentDisabledScreen = () => {
  const { selectedOrganization, isFreeTier } = useDashboardContext();
  const history = useHistory();
  const redirectToBilling = () => history.push(Routes.dashboard_organization_billingWithId(selectedOrganization.id || ""));

  return (
    <Flex>
      <TopMenuBar title="New deployment" breadcrumbs={[]} icon={deploymentIcon}>
        <Box padding="4">
          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <AlertDescription>
              You have reached the maximum number of deployments allowed for this project.{" "}
              {isFreeTier && (
                <Text as="span">
                  <Button size="sm" color="blue.500" display="inline" variant="link" onClick={redirectToBilling}>
                    Click here to add a payment method
                  </Button>{" "}
                  and unlock more deployments.
                </Text>
              )}
            </AlertDescription>
          </Alert>
        </Box>
      </TopMenuBar>
    </Flex>
  );
};
