//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton, InputControl, SingleSelectControl, SliderControl, TextareaControl } from "@arangodb/ui";
import { Alert, AlertIcon, Button, Grid, ModalFooter, Text } from "@chakra-ui/react";
import { Form, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { Notebook } from "../../../api/notebook/v1/notebook";
import { NotebookFormValues } from "./CreateNotebookView";
import { useFetchNotebookModels } from "./useFetchNotebookModels";

export const EditNotebookForm = ({ oldNotebook, onClose }: { oldNotebook: Notebook; onClose: () => void }) => {
  const { setFieldValue, isSubmitting, values, dirty } = useFormikContext<NotebookFormValues>();
  const { isLoading: isLoadingNotebookModels, data: notebookModels = {} } = useFetchNotebookModels();
  const notebookModelsItems = notebookModels.items || [];

  const memoizedNotebookModelOptions = useMemo(
    () =>
      notebookModelsItems.map((model) => ({
        label: `${model.name} (${model.cpu} CPU unit(s) and ${model.memory}GB memory)`,
        value: model.id || "",
        isDisabled: oldNotebook.model?.disk_size && model.max_disk_size ? model.max_disk_size < oldNotebook.model?.disk_size : false,
      })),
    [notebookModelsItems, oldNotebook.model?.disk_size]
  );

  const hasNotebookModels = !!notebookModelsItems.length;
  const currentModelId = values.modelId;

  const {
    initial_disk_size: currentInitialDiskSize = 1,
    max_disk_size: maxDiskSize = 1,
    min_disk_size: minDiskSize = 1,
  } = notebookModelsItems.find((model) => model.id === currentModelId) || {};

  useEffect(() => {
    if (oldNotebook.model?.notebook_model_id !== currentModelId) {
      setFieldValue("diskSize", currentInitialDiskSize);
    }
  }, [currentModelId]);

  return (
    <Grid as={Form} gap="4" templateColumns="1fr">
      <InputControl
        isRequired
        label="Name"
        name="name"
        inputProps={{
          placeholder: "Give your notebook a recognizable name",
        }}
      />

      <TextareaControl name="description" label="Description" inputProps={{ placeholder: "Short description" }} />

      <SingleSelectControl
        label="Model"
        isRequired
        name="modelId"
        isDisabled={isLoadingNotebookModels || !hasNotebookModels}
        selectProps={{
          isLoading: isLoadingNotebookModels,
          options: memoizedNotebookModelOptions,
          placeholder: "Select Model",
          noOptionsMessage: () => <Text>No models found</Text>,
        }}
        tooltip="Specifies the amount of CPU units and the memory allocated to this notebook."
      />

      {(oldNotebook.model?.disk_size || minDiskSize) === maxDiskSize ? (
        <Alert marginTop="4" status="warning" borderRadius="md">
          <AlertIcon />
          <Text>The maximum disk size for the current model is reached. Select a larger model to increase the disk size.</Text>
        </Alert>
      ) : (
        <SliderControl
          isRequired
          min={oldNotebook.model?.disk_size || minDiskSize}
          max={maxDiskSize}
          label="Disk Size"
          name="diskSize"
          textAlign="center"
          maxText="GB"
          minText="GB"
          sliderValueText="GB"
          sliderProps={{
            width: "90%",
            marginTop: "8",
            step: 1,
          }}
        />
      )}

      <Alert marginTop="4" status={"info"} borderRadius="md">
        <AlertIcon />
        <Text>
          The Notebook is currently configured with <Text as="b">{oldNotebook.model?.disk_size} GiB</Text> of Disk Size. Notice that the disk size cannot be
          downgraded.{" "}
        </Text>
      </Alert>

      <ModalFooter gap="4">
        <Button onClick={onClose} disabled={isSubmitting}>
          Close
        </Button>
        <FormButton colorScheme="green" alignContent="center" isLoading={isSubmitting} autoFocus isDisabled={!hasNotebookModels || !dirty}>
          Save
        </FormButton>
      </ModalFooter>
    </Grid>
  );
};
