//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import { CreditUsageReportList } from "../../../api/credits/v1/credits";
import usePagination from "../../../ui/hooks/usePagination";
import apiClients from "../../../api/apiclients";
import { useDashboardContext } from "../../DashboardContextProvider";

interface ErrorWithStatus extends Error {
  status: number | undefined;
}
export const useFetchCreditUsageReports = () => {
  const { paginationObject } = usePagination();
  const { selectedOrganization } = useDashboardContext();

  const fetchCreditUsageReports = async () => {
    const reports = await apiClients.creditBundleClient.ListCreditUsageReports({
      organization_id: selectedOrganization?.id || "",
    });

    return reports;
  };

  return useQuery<CreditUsageReportList, ErrorWithStatus>([paginationObject.page, paginationObject.pageSize, "creditUsageReports"], fetchCreditUsageReports);
};
