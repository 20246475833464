import React from "react";
import { useHistory } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { Routes } from "../../routes";
import { ContentActionButtonNew } from "../../ui/_buttons";
import { Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/_section";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { BudgetHelper } from "../BudgetHelper";
import { useDashboardContext } from "../DashboardContextProvider";
import { HistoryHelper } from "../HistoryHelper";
import DeploymentList from "../deployment/DeploymentList";
import RedirectToBillingPopover from "../pricing/RedirectToBillingPopover";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { useProjectDetailsContext } from "./ProjectDetailsContextProvider";

export const ProjectDeploymentsView = () => {
  const { checkProjectPermission } = useCheckPermissionByUrl();
  const { selectedOrganization, topMenuInfo, isFreeTier } = useDashboardContext();
  const hasDeploymentCreationPermissions = checkProjectPermission("data.deployment.create");
  const { project, deployments, onResumeDeployment, prepaidDeployments } = useProjectDetailsContext();
  const isProjectDeleted = !!project?.is_deleted;
  const requiresPrepaidDeployments = !!selectedOrganization?.requires_prepaid_deployments;
  const history = useHistory();

  const { permissionCache, eventSubscriptionManager, loading } = useWithRefresh();
  const onDeploymentSelected = (deploymentID: string) => {
    history.push(Routes.dashboard_project_deployment_detailsWithId(project.id || "", deploymentID));
  };

  const hasReachedDeploymentCreationLimit = BudgetHelper.disableDeploymentCreate(deployments);
  const isNewDeploymentDisabled = requiresPrepaidDeployments || hasReachedDeploymentCreationLimit;
  const hasReachedFreeTierLimit = isFreeTier && hasReachedDeploymentCreationLimit;

  return (
    <>
      <Section>
        <SectionHead>
          <SectionHeader
            title="Deployments"
            loading={loading}
            help={
              <div>
                <p className="para">Deployments are instances of an ArangoDB cluster.</p>
                <p className="para">Each deployment has its own endpoint (URL) and its own set of credentials for accessing it.</p>
              </div>
            }
          />
          <div>
            <SectionButtons>
              {hasDeploymentCreationPermissions && !isProjectDeleted && !requiresPrepaidDeployments && (
                <RedirectToBillingPopover
                  popupText="You have reached the maximum number of deployments allowed for this project."
                  organizationId={selectedOrganization.id || ""}
                  disabled={!hasReachedFreeTierLimit}
                >
                  <ContentActionButtonNew
                    primary
                    content="New deployment"
                    disabled={isNewDeploymentDisabled}
                    onClick={() => {
                      HistoryHelper.push(history, Routes.dashboard_project_deployments_createWithId(project.id || ""), topMenuInfo?.getTitle());
                    }}
                  />
                </RedirectToBillingPopover>
              )}
              {requiresPrepaidDeployments && (
                <Popup
                  trigger={
                    <span>
                      <ContentActionButtonNew primary content="New deployment" disabled />
                    </span>
                  }
                  content={<p className="para">Your organization does not allow creating deployments outside your contract.</p>}
                />
              )}
            </SectionButtons>
          </div>
        </SectionHead>
        <SectionContent>
          <DeploymentList
            onResumeDeployment={onResumeDeployment}
            topMenuInfo={topMenuInfo}
            organization={selectedOrganization}
            project={project}
            onDeploymentSelected={onDeploymentSelected}
            eventSubscriptionManager={eventSubscriptionManager}
            permissionCache={permissionCache}
            loading={loading}
            deployments={deployments}
            prepaidDeployments={prepaidDeployments}
          />
        </SectionContent>
      </Section>
    </>
  );
};
