//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { CreditsStats } from "./CreditsBundleStats";
import { Flex, Heading, Link, Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useDashboardContext } from "../../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../../sidebar/useCheckPermissionByUrl";
import CreditsProjectionsGraph from "./CreditProjectionsGraph";
import { CreditsBundleConsumptionList } from "./CreditsBundleConsumptionList";
import { CreditsBundleCTAInfo } from "./CreditsBundleCTAInfo";
import { CreditDebtView } from "./CreditsBundleDebtView";
import { ActiveCreditsBundlePurchaseView, ExpiredCreditsBundlePurchaseHistory } from "./CreditsBundlePurchaseHistory";
import { CreditsUsageGraph } from "./CreditUsageRateGraph";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import useErrorToast from "../../../util/hooks/useErrorToast";
import { SectionSpinner } from "../../../components/SectionSpinner";
import { useFetchCreditDebt } from "./useFetchCreditDebt";
import { useFetchCreditBundles } from "./useFetchCreditBundles";

export const CreditsBundleView = () => {
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const { isFreeTier, isProTier } = useDashboardContext();

  const canGetConsumptionReport = !!checkOrgPermission("credit.creditusagereport.get");
  const canGetCreditsUsageGraph = !!checkOrgPermission("credit.creditusagereport.get");
  const canGetUsageProjections = !!checkOrgPermission("credit.creditbundleusageprojection.get");
  const hasCreditsViewPermission = !!checkOrgPermission("credit.creditbundle.list");
  const canGetCreditBundleDebtInfo = !!checkOrgPermission("credit.creditdebt.get");

  const { data: creditBundles, isLoading: isLoadingCreditBundles, error: creditBundlesError } = useFetchCreditBundles();
  useErrorToast({ errorMessage: creditBundlesError ? "Error while fetching credit bundles" : undefined });

  const { data: creditDebt, isLoading: isLoadingCreditDebt, error: creditDebtError } = useFetchCreditDebt();
  useErrorToast({ errorMessage: creditDebtError ? "Error while fetching credit debt" : undefined });

  if (isLoadingCreditBundles || isLoadingCreditDebt) {
    return (
      <Flex direction="column" gap={4}>
        <Heading fontSize="2xl">Credit bundles</Heading>
        <SectionSpinner loadingText="Fetching credit bundles" />
      </Flex>
    );
  }

  if (isFreeTier || isProTier || isEmpty(creditBundles)) {
    return (
      <Flex direction="column" gap={4}>
        <Heading fontSize="2xl">Credit bundles</Heading>
        <CreditsBundleCTAInfo />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={4}>
      <Heading fontSize="2xl">Credits & Usage</Heading>

      <Box>
        <Link as="a" href="https://docs.arangodb.com/stable/arangograph/organizations/credits-and-usage" target="_blank">
          Learn more about how credits work <ExternalLinkIcon />
        </Link>
      </Box>

      {!creditDebt?.amount && <CreditsStats creditBundle={creditBundles} />}

      {canGetCreditBundleDebtInfo && <CreditDebtView creditDebt={creditDebt} />}

      {hasCreditsViewPermission && !isEmpty(creditBundles) && (
        <>
          <ActiveCreditsBundlePurchaseView creditBundles={creditBundles} />
          <ExpiredCreditsBundlePurchaseHistory creditBundles={creditBundles} />
        </>
      )}

      {canGetCreditsUsageGraph && !isEmpty(creditBundles) && <CreditsUsageGraph />}

      {canGetUsageProjections && !isEmpty(creditBundles) && !creditDebt?.amount && <CreditsProjectionsGraph />}

      {canGetConsumptionReport && <CreditsBundleConsumptionList />}
    </Flex>
  );
};
