//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../../api/apiclients";
import useOrganisationStore from "../../../util/storage/OrganisationStore";

const fetchProvider = async (id: string) => {
  const providerResponse = await apiClients.platformClient.GetProvider({ id });
  return providerResponse;
};

export const useProvider = (providerId: string | undefined) => {
  const setStoreProvider = useOrganisationStore((state) => state.setProvider);
  const storedProvider = useOrganisationStore((state) => state.getProvider(providerId || ""));

  return useQuery(["provider", providerId], () => fetchProvider(providerId || ""), {
    enabled: !!providerId, // Only run the query if providerId is truthy
    initialData: storedProvider,
    onSuccess: (data) => {
      if (!data) return;
      setStoreProvider(data);
    },
  });
};
