//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Project as ApiProject, ProjectList as ApiProjectList } from "../../api/lib";
import { Routes } from "../../routes";
import { Loading } from "../../ui/lib";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { ProjectNotFound } from "../project/ProjectNotFound";
import { ProjectRoutes } from "./ProjectRoutes";

const useSelectedProject = () => {
  const { projects } = useDashboardContext();

  const match = useRouteMatch<{ projectId: string }>(Routes.dashboard_project);

  const [selectedProject, setSelectedProject] = React.useState<ApiProject>();
  const findProject = (projects: ApiProjectList, projectId: string): ApiProject | undefined => {
    return projects.items && projects.items.find((p) => p.id == projectId);
  };

  const findAndSetSelectedProject = (params: { projectId: string } | undefined) => {
    if (projects && params) {
      const projectId = params.projectId;
      const project = findProject(projects, projectId);
      setSelectedProject(project);
      useGlobalStore.setState({ project });
    }
  };
  const [prevLocation, setPrevLocation] = React.useState<any>();
  const location = useLocation();

  React.useEffect(() => {
    findAndSetSelectedProject(match?.params);
  }, [projects]);
  React.useEffect(() => {
    if (location !== prevLocation) {
      findAndSetSelectedProject(match?.params);
      setPrevLocation(location);
    }
    return () => {
      useGlobalStore.setState({ project: {} });
    };
  }, [projects, location]);

  return { findAndSetSelectedProject, selectedProject };
};

const Project = () => {
  const { projects, onProjectDeleted } = useDashboardContext();
  const { selectedProject } = useSelectedProject();
  if (!projects) {
    return <Loading />;
  }
  if (!selectedProject) {
    return <ProjectNotFound />;
  }
  return (
    <div>
      <ProjectRoutes onProjectDeleted={onProjectDeleted} />
    </div>
  );
};
export default Project;
