//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Text, Button, Flex } from "@chakra-ui/react";
import React from "react";

export const CreditsBundleCTAInfo = () => {
  return (
    <Flex direction="column" gap={6}>
      <Text>
        The ArangoGraph credit model is a versatile prepaid model that allows you to purchase credits and use them in a flexible way, based on what you have
        running in ArangoGraph. Instead of purchasing a particular deployment for a year, you can purchase a number of ArangoGraph credits that expire a year
        after purchase. These credits are then consumed over that time period, based on the deployments you run in ArangoGraph. To purchase credits for your
        organization, you need to get in touch with the ArangoDB team.
      </Text>

      <Flex gap={2}>
        <Button as="a" href="https://www.arangodb.com/contact" target="_blank" colorScheme="green">
          Contact us
        </Button>
        <Button as="a" href="https://docs.arangodb.com/stable/arangograph/organizations/credits-and-usage" target="_blank">
          Learn more
        </Button>
      </Flex>
    </Flex>
  );
};
