//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { BudgetHelper } from "../BudgetHelper";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const NewProjectButton = () => {
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const history = useHistory();
  const { projects } = useDashboardContext();

  const { selectedOrganization } = useDashboardContext();
  const hasCreateProjectPermission = checkOrgPermission("resourcemanager.project.create");

  // BudgetHelper treats 'no projects' as budget limit reached, which is incorrect. So we need a special case here
  const hasReachedBudgetLimit = projects ? BudgetHelper.disableProjectCreate(projects) : false;
  const isDisabled = hasReachedBudgetLimit || !hasCreateProjectPermission;
  return (
    <>
      <Popover
        trigger="hover"
        offset={[64, 8]}
        // this ensures the if the button is disabled, the popover will work
        isOpen={isDisabled ? undefined : false}
      >
        <PopoverTrigger>
          <Flex display="inline-flex" marginBottom="4">
            <Button
              colorScheme="green"
              size="md"
              onClick={() => {
                history.push(Routes.dashboard_organization_projects_createWithId(selectedOrganization?.id || ""));
              }}
              rightIcon={<Icon marginLeft="16px" aria-label="Add new project" as={AddIcon} />}
              // Note: !projects means it's still loading, it's a hack because of a lack of loading state
              isDisabled={isDisabled || !projects}
            >
              New Project
            </Button>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          _focus={{
            outline: "none",
          }}
        >
          <PopoverArrow />
          <PopoverBody>
            <ButtonPopoverBody hasCreateProjectPermission={hasCreateProjectPermission} hasReachedBudgetLimit={hasReachedBudgetLimit} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

const ButtonPopoverBody = ({ hasReachedBudgetLimit, hasCreateProjectPermission }: { hasReachedBudgetLimit: boolean; hasCreateProjectPermission: boolean }) => {
  const { isFreeTier, selectedOrganization } = useDashboardContext();
  const history = useHistory();

  if (!hasCreateProjectPermission) {
    return (
      <Flex direction="column" gap="4">
        <Text>You do not have permission to create a new project in this organization.</Text>
        <Text>Please contact your organization administrator to request access.</Text>
      </Flex>
    );
  }

  if (hasReachedBudgetLimit && isFreeTier) {
    return (
      <Flex direction="column" gap="4">
        <Text>This feature requires a payment method.</Text>
        <Text>Upgrade your account to immediately unlock all features.</Text>
        <Button
          colorScheme="green"
          onClick={() => {
            history.push(Routes.dashboard_organization_billingWithId(selectedOrganization?.id || ""));
          }}
        >
          Unlock all features
        </Button>
      </Flex>
    );
  }

  if (hasReachedBudgetLimit && !isFreeTier) {
    return (
      <Flex direction="column" gap="4">
        <Text>You have reached the maximum number of projects allowed for this organization.</Text>
        <Text>Upgrade your account or contact support to create more projects.</Text>
        <Button
          colorScheme="green"
          onClick={() => {
            history.push(Routes.dashboard_organization_billingWithId(selectedOrganization?.id || ""));
          }}
        >
          Upgrade your account
        </Button>
      </Flex>
    );
  }

  return null;
};
