//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from "recharts";
import { CreditBundleUsageProjection_Projection } from "../../../api/credits/v1/credits";
import { Box } from "../../../ui/_box";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Alert, AlertIcon, Flex, Heading, Text } from "@chakra-ui/react";
import useErrorToast from "../../../util/hooks/useErrorToast";
import { SectionSpinner } from "../../../components/SectionSpinner";
import { useFetchCreditBundleUsageProjection } from "./useFetchCreditBundleUsageProjection";

const ProjectionTooltip = ({ active, payload }: { active?: boolean; payload?: Array<Payload<ValueType, NameType>> }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const { value, timestamp } = payload[0].payload || [];
  return (
    <Box backgroundColor="white" padding="10px" borderRadius="md" boxShadow="0 0 4px 0 var(--grey-100)">
      <div>
        Estimated Remaining Credits:<b> {Number(value).toFixed(2)}</b>
      </div>
      <div>
        Date:<b> {moment(timestamp).format("MMM DD, YYYY")}</b>
      </div>
    </Box>
  );
};

export const ProjectionsGraph = ({ data = [] }: { data: CreditBundleUsageProjection_Projection[] }) => {
  if (!data || !data.length) {
    return (
      <Alert itemType="info" borderRadius="md" variant="left-accent">
        <AlertIcon />
        No Projection data available
      </Alert>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" tickFormatter={(timestamp) => moment(timestamp).format("MMM DD, YYYY")} />
        <YAxis />
        <Tooltip content={<ProjectionTooltip />} />
        <Legend />
        <Line dataKey="value" name="Credits Projections" fill="#7ead35" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const CreditsProjectionsGraph = () => {
  const { data: creditBundleUsageProjection, isLoading, error } = useFetchCreditBundleUsageProjection();
  useErrorToast({ errorMessage: error ? "Error fetching projections" : undefined });

  const projections = creditBundleUsageProjection?.projections?.map((item) => ({ ...item, value: item.value || 0 })) || [];

  if (isLoading) {
    return (
      <Flex direction="column" gap={4}>
        <Heading fontSize="2xl">Credits projection by usage</Heading>
        <SectionSpinner loadingText="Fetching credits projection data ..." />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={4}>
      <Heading fontSize="2xl">Credits projection by usage</Heading>
      <Text>This projection is based on the usage from the last 30 days and may vary if the user's actual usage changes.</Text>

      <Box>
        <ProjectionsGraph data={projections} />
      </Box>
    </Flex>
  );
};
export default CreditsProjectionsGraph;
