//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { ImageFull } from "../../ui/lib";

import awsLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/aws-logo.svg";
import gcpLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/google-cloud-logo.svg";
import azureLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/microsoft-azure-logo.svg";

export const ProviderIconView = ({ ...args }: { providerId: string | undefined }) => {
  if (args.providerId === "aws") {
    return <ImageFull src={awsLogo} alt="AWS" data-testid="awsLogo" />;
  }
  if (args.providerId === "gcp") {
    return <ImageFull src={gcpLogo} alt="Google Cloud Platform" data-testid="googleCloudLogo" />;
  }
  if (args.providerId === "aks") {
    return <ImageFull src={azureLogo} alt="Azure" data-testid="azureLogo" />;
  }
  return <span data-testid="noProviderLogo"></span>;
};
